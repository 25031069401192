import './styles.css';

import classNames from 'classnames';
import React, { useState } from 'react';
import Popover from 'react-tiny-popover';

import CopyIcon from './CopyIcon';

interface CopyBadgeProps {
  /** Optional fill color for the copy icon */
  iconFillColor?: string;
  /** classNames to be added to the copy badge */
  copyBadgeClassNames?: string;
  /** classNames to be added to the container */
  valueContainerClassNames?: string;
  /** boolean for if the copy icon should always be visible */
  shouldAlwaysShowIcon?: boolean;
  value: React.ReactNode;
}

const CopyBadge: React.FC<CopyBadgeProps> = ({
  copyBadgeClassNames,
  valueContainerClassNames,
  shouldAlwaysShowIcon = false,
  value,
  iconFillColor,
}) => {
  if (!value) {
    return null;
  }

  const [shouldShowCopyIcon, setShouldShowCopyIcon] =
    useState<boolean>(shouldAlwaysShowIcon);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [copyTextValue, setCopyTextValue] = useState<string>('');

  const handleOnMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      // There are some elements, that have children that also render a <Value />. In this case when you hover that element, you see two copy icons.
      // This check makes sure that there is only one copy of the CopyBadge-img, so that you don't see multiple copy badges on the same element.
      event.currentTarget.getElementsByClassName('CopyBadge-img').length >= 2 ||
      event.currentTarget.querySelector('[data-not-copyable]')
    ) {
      return null;
    }

    if (event.currentTarget.innerText) {
      setShouldShowCopyIcon(true);
      setCopyTextValue(event.currentTarget.innerText);
    }
  };

  return (
    <div
      className="flex w-full items-center"
      onMouseOver={handleOnMouseOver}
      onMouseLeave={() =>
        shouldAlwaysShowIcon
          ? shouldAlwaysShowIcon
          : setShouldShowCopyIcon(false)
      }
    >
      <div className={valueContainerClassNames}>{value}</div>
      <Popover
        isOpen={isPopoverOpen}
        position={['top']}
        content={() => (
          <div className="CopyBadge-popover flex justify-center items-center px-2 py-1 mb-1 text-sm bg-blue-100 text-blue-500 font-medium rounded-md">
            Copied to clipboard
          </div>
        )}
        containerStyle={{ zIndex: '99999' }}
      >
        <div
          className={classNames(
            'CopyBadge-img cursor-pointer self-center h-6 w-6 flex-shrink-0 ml-1 rounded-2xl -my-2',
            copyBadgeClassNames,
            {
              invisible: !shouldShowCopyIcon,
              'active:bg-gray-300': !shouldAlwaysShowIcon,
            },
          )}
          onClick={e => {
            navigator.clipboard.writeText(copyTextValue.replace(/\s+/g, ' '));
            setIsPopoverOpen(true);
            setTimeout(() => setIsPopoverOpen(false), 1500);
          }}
        >
          <CopyIcon fill={iconFillColor || ''} />
        </div>
      </Popover>
    </div>
  );
};

export default CopyBadge;
