import React from 'react';
import moment from 'moment-timezone';
import CopyBadge from '@frontend/components/elements/CopyBadge';
import { ClaimType } from '@common/types/Claim';
import { InvolvedPartyType } from '@common/types/Claim/Vehicle';

import DescriptionList from '../partials/DescriptionList';

import { useLazyQuery, gql } from '@apollo/client';

const OTHER_PARTY_LICENSE_PLATE = 'OTHER_PARTY_LICENSE_PLATE';
const GET_COMPLIANCE_PDF = gql`
  query GetClaimCompliancePdf($id: ID!) {
    claim(id: $id) {
      id
      compliancePdf {
        base64EncodedPdf
      }
    }
  }
`;

export default function GodUtilities({ claim }: { claim: ClaimType }) {
  const [fetchCompliancePdf, { loading: loadingCompliancePdf }] = useLazyQuery(
    GET_COMPLIANCE_PDF,
    {
      variables: { id: claim.id },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        const a = document.createElement('a');
        const id = `${
          claim.confirmationNumber || claim.draftNumber || `U-${claim.id}`
        }_${moment().tz('America/Los_Angeles').format('YYYY_MM_DD_HH_mm_z')}`;

        a.href = `data:application/pdf;base64,${data.claim.compliancePdf.base64EncodedPdf}`;
        a.download = `${id}.pdf`;
        a.click();
      },
      onError: error => {
        window.alert(`Failed to load: ${JSON.stringify(error)}`);
      },
    },
  );

  return (
    <DescriptionList
      title="Demiurgic utilities"
      content={
        <div className="flex">
          <div
            className="flex-1 grid col-gap-4"
            style={{ gridTemplateColumns: 'auto minmax(0, 1fr)' }}
          >
            <div className="text-sm whitespace-no-wrap text-gray-500">
              Internal ID
            </div>
            <CopyBadge value={claim.id} />
            <div className="text-sm whitespace-no-wrap text-gray-500">
              Draft #
            </div>
            <CopyBadge value={claim.draftNumber} />
            <div className="text-sm whitespace-no-wrap text-gray-500">
              Confirmation #
            </div>
            <CopyBadge value={claim.confirmationNumber || 'None assigned'} />
          </div>
          <div>
            <button
              className="btn"
              onClick={() => fetchCompliancePdf()}
              disabled={loadingCompliancePdf}
            >
              {loadingCompliancePdf
                ? `Downloading compliance PDF...`
                : `Download compliance PDF export`}
            </button>
          </div>
        </div>
      }
    />
  );
}

export const filterInvolvedPartyMedia = (
  claim: ClaimType,
  involvedParty: InvolvedPartyType,
) => {
  const claimMediaList = claim.claimMedia;
  const claimMediaElement = claimMediaList.find(
    media =>
      media.ofLicensePlateOfInvolvedPartyId === involvedParty.id &&
      media.feature === OTHER_PARTY_LICENSE_PLATE &&
      media.source,
  );

  if (claimMediaElement) {
    return claimMediaElement;
  }

  return null;
};
