import React from 'react';
import { Link } from 'react-router-dom';

export default function TermsWidget({ tenantName }: { tenantName?: string }) {
  return (
    <div
      style={{ fontSize: '0.65rem' }}
      className="mt-5 ClaimWorkflowInner text-justify text-cool-gray-500"
    >
      By clicking "I Agree and Continue," you acknowledge the Assured{' '}
      <Link
        to="/privacy"
        target="_blank"
        className="underline font-bold uppercase text-blue-600 hover:text-blue-700"
      >
        Privacy&nbsp;Policy
      </Link>
      , agree to the Assured{' '}
      <Link
        to="/terms"
        target="_blank"
        className="underline font-bold uppercase text-blue-600 hover:text-blue-700"
      >
        Terms of Service
      </Link>
      , including the arbitration provision, and waive the right to a trial by
      jury or to participate in any class action or representative proceeding
      against Assured. By clicking "I Agree and Continue," you are not waiving
      any such rights in reference to your claim with{' '}
      {tenantName || 'your insurer'}. You also agree to receive texts about the
      status of your online claim submission from or on behalf of{' '}
      {tenantName || 'your insurer'}.
    </div>
  );
}
