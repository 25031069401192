import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import { ClaimWorkflowStepComponent, DateTimeStepComponent } from '@common/types/ClaimWorkflow';

import {
  ClaimWorkflowStepFragmentData
} from '../components/ClaimWorkflow/types/stepComponentTypes';

const ONE_DAY = 1000 * 60 * 60 * 24;

export const generateValidationSchema = ({
  step,
}: {
  step: ClaimWorkflowStepFragmentData;
}) => {
  const skippable = !!step.content.skip_label || !!step.content.skippable;

  const shape = {};

  const makeComponentSpec = (component: ClaimWorkflowStepComponent) => {
    let v;

    if (component.type === 'string') {
      v = yup.string().nullable();
      if (component.required || !skippable) {
        v = v.required('Please fill out this field.');
      }
      if (component.mode === 'phone_number') {
        v = v.test(
          'is-valid-phone-number',
          'Please enter a valid phone number.',
          (value: any) =>
            (!value && skippable) || (value && isPossiblePhoneNumber(value)),
        );
      } else if (component.mode === 'email') {
        v = v.email('Please enter a valid email address.');
      } else if (component.mode === 'license_plate') {
        v = v.test(
          'is-valid-license-plate',
          'Please enter a valid license number.',
          (value: any) =>
            (!value && skippable) || /^[A-Z0-9 ]{3,10}$/.test(value),
        );
      } else if (component.mode === 'full_name') {
        v = v.test(
          'is-valid-full-name',
          'Please enter both a first and last name.',
          (value: any) => /\w+\s+\w+/.test(value),
        );
      } else if (component.mode === 'insurance_number') {
        v = v.test(
          'is-valid-insurance-number',
          'Please enter a valid policy number.',
          (value: any) =>
            value.indexOf(' ') === -1 && value.length > 3 && value.length < 40,
        );
      } else if (component.mode === 'currency') {
        v = v.test(
          'is-reasonable-currency',
          'Please enter a value less than $10,000,000, or call us for assistance.',
          (value: any) => value < 10_000_000 * 100,
        );
      } else if (component.mode === 'number') {
        if (component.maximum) {
          v = v.test(
            'is-below-max',
            `Please enter a value less than ${component.maximum}.`,
            (value: any) => +value < component.maximum!,
          );
        }
        if (component.minimum) {
          v = v.test(
            'is-above-min',
            `Please enter a value greater than ${component.minimum}.`,
            (value: any) => +value > component.minimum!,
          );
        }
      }
    } else if (component.type === 'list') {
      v = yup.array();
      if (component.required) {
        v = v.required('Please list at least one.');
        if (component.fixed_length) {
          v = v.test(
            'is-required-fixed-length',
            'Please complete every field.',
            (value: any) => {
              if (!Array.isArray(value)) {
                return false;
              }
              if (value.length !== component.fixed_length) {
                return false;
              }
              for (const subdef of component.list_components) {
                if (
                  subdef.required &&
                  !value.every((v: any) => !!v[subdef.field!])
                ) {
                  return false;
                }
              }
              return true;
            },
          );
        }
      }
    } else if (component.type === 'select_multi') {
      v = component.single_toggle ? yup.mixed() : yup.array();
      if (component.required) {
        v = v.required('Please select at least one option.');
      }
    } else if (component.type === 'datetime') {
      v = yup.date().nullable();
      if (!skippable) {
        v = v.required('Please enter a date.');
      }
      if ((component as DateTimeStepComponent).date_mode === 'past') {
        v = v.max(
          new Date(Date.now() + ONE_DAY),
          'Please enter a date in the past.',
        );
      } else if ((component as DateTimeStepComponent).date_mode === 'future') {
        v = v.min(
          new Date(Date.now() - ONE_DAY),
          'Please enter a date in the future.',
        );
      }
    } else if (component.type === 'vehicle_damage_picker') {
      if (component.required) {
        v = yup.object().nullable();
        v = v.test(
          'is-valid-damage',
          'Please mark the damage before continuing.',
          (value: any) => {
            return value && Object.values(value).some(mesh => !!mesh);
          },
        );
      }
    } else if (component.type === 'signature') {
      if (component.required) {
        v = yup.string().nullable().required('Please sign to continue.');
      }
    } else if (
      component.type === 'select' ||
      component.type === 'select_or_create'
    ) {
      if (component.required) {
        v = yup
          .mixed()
          .nullable()
          .required('Please select an option to continue.');
      }
    } else if (component.type === 'vehicle_seatmap' && component.single) {
      v = yup.string().nullable().required('Please select a seat to continue.');
    }

    return v;
  };

  for (const component of step.content.step_components) {
    let v = makeComponentSpec(component);

    if (component.field && v) {
      Object.assign(shape, {
        [component.field]: v,
      });
    }
  }

  const schema = yup.object().shape(shape);
  return schema;
};
