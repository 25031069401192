import React from 'react';
import { MediaType } from '@common/types/Claim/Media';
import { ChildScreenProps } from '../types';
import DescriptionList from '../partials/DescriptionList';
import ClaimWorkflowStep from '@frontend/components/ClaimWorkflow/ClaimWorkflowStep';
import { MediaLoader } from '@frontend/components/ClaimView/components/Media/MediaLoader';
import GodUtilities, { filterInvolvedPartyMedia } from './GodUtilities';

import 'react-select/dist/react-select.css';
const LICENSE_PLATE = [
  {
    field: 'manualVehicleLicensePlateState',
    title: 'License plate state',
    type: 'select',
    mode: 'dropdown',
    source: 'us-states',
    no_scroll_into_view: true,
  },
  {
    field: 'manualVehicleLicensePlate',
    title: 'License plate',
    type: 'string',
  },
];
const specs = {
  INSURANCE_CARD: [
    {
      field: 'insuredName',
      title: "Primary insured's full legal name",
      type: 'string',
    },
    {
      field: 'agency',
      title: 'Insurance agency',
      type: 'string',
    },
    {
      field: 'agencyPolicyNumber',
      title: 'Policy number',
      type: 'string',
    },
  ],
  DRIVER_LICENSE: [
    {
      field: 'driverLicenseName',
      title: "Person's full legal name",
      type: 'string',
    },
    {
      field: 'driverLicenseState',
      title: 'Issuing state',
      type: 'select',
      mode: 'dropdown',
      source: 'us-states',
      no_scroll_into_view: true,
    },
    {
      field: 'driverLicenseNumber',
      title: 'License number',
      type: 'string',
    },
  ],
  LICENSE_PLATE: [...LICENSE_PLATE],
  OTHER_PARTY_LICENSE_PLATE: [...LICENSE_PLATE],
};

type valueof<T> = T[keyof T];
function Editor({
  spec,
  existing_value,
  updateClaim,
}: {
  spec: valueof<typeof specs>;
  existing_value: any;
  updateClaim: (data: any) => void;
}) {
  return (
    <ClaimWorkflowStep
      onSubmit={data => {
        window.analytics.track('God Edit Submit', {
          keys: Object.keys(data),
        });
        updateClaim(data);
      }}
      step={{
        id: 'example',
        key: '',
        stepIndex: 0,
        totalSteps: 0,
        notifications: [],
        content: {
          skippable: true,
          manual_submit_label: 'Save data',
          step_components: (spec as any[]).map((component_: any) => {
            let component = Object.assign({}, component_, {
              id: component_.field,
              existing_value: existing_value[component_.field] || null,
            }) as any;
            return component;
          }),
        },
      }}
    />
  );
}

export default function God({ claim, user, updateClaim }: ChildScreenProps) {
  let claimEditables: {
    title: string;
    source?: string;
    makeUpdate: any;
    feature: string;
    existing_value?: any;
  }[] = [
    {
      title: 'Filer insurance card',
      source: claim?.filerInsuranceCardDocument?.source,
      makeUpdate: (data: any) => ({
        filerUserEnteredInsuranceCardDetails: JSON.stringify(data),
      }),
      feature: 'INSURANCE_CARD',
      existing_value: claim.filerUserEnteredInsuranceCardDetails
        ? JSON.parse(claim.filerUserEnteredInsuranceCardDetails)
        : {},
    },
    {
      title: 'Filer driver license',
      source: claim?.filerDriverLicenseDocument?.source,
      makeUpdate: (data: any) => ({
        filerUserEnteredDriverLicenseDetails: JSON.stringify(data),
      }),
      feature: 'DRIVER_LICENSE',
      existing_value: claim.filerUserEnteredDriverLicenseDetails
        ? JSON.parse(claim.filerUserEnteredDriverLicenseDetails)
        : {},
    },
    {
      title: 'Filer license plate',
      source: claim?.claimMedia?.find(
        (m: MediaType) => m.feature === 'LICENSE_PLATE',
      )?.source,
      makeUpdate: (data: any) => ({
        manualVehicleLicensePlate: data.manualVehicleLicensePlate,
        manualVehicleLicensePlateState: data.manualVehicleLicensePlateState,
      }),
      feature: 'LICENSE_PLATE',
      existing_value: {
        manualVehicleLicensePlateState: claim.manualVehicleLicensePlateState,
        manualVehicleLicensePlate: claim.manualVehicleLicensePlate,
      },
    },
  ];

  for (const party of claim.involvedParties) {
    const involvedPartyMedia = filterInvolvedPartyMedia(claim, party);

    const descriptor = `Involved party: ${party.type}`;
    if (party.insuranceCardDocument?.source) {
      claimEditables.push({
        title: `${descriptor} insurance card`,
        source: party.insuranceCardDocument.source,
        makeUpdate: (data: any) => ({
          involvedParties: {
            update: [
              {
                data: { userContent: JSON.stringify(data) },
                where: { id: party.id },
              },
            ],
          },
        }),
        feature: 'INSURANCE_CARD',
        existing_value: party.userContent
          ? JSON.parse(party.userContent)
          : null,
      });
    }
    if (party.driverLicenseDocument?.source) {
      // fall through
    }
    if (party.licensePlateMedia?.source) {
      claimEditables.push({
        title: `${descriptor} license plate`,
        source: party.licensePlateMedia.source,
        makeUpdate: (data: any) => ({
          involvedParties: {
            update: [
              {
                data: data,
                where: { id: party.id },
              },
            ],
          },
        }),
        feature: 'LICENSE_PLATE',
        existing_value: {
          manualVehicleLicensePlateState: party.manualVehicleLicensePlateState,
          manualVehicleLicensePlate: party.manualVehicleLicensePlate,
        },
      });
    }

    if (involvedPartyMedia) {
      claimEditables.push({
        title: `Other party: ${party?.insuredVehicle?.make} license plate`,
        source: involvedPartyMedia.source,
        makeUpdate: (data: any) => ({
          involvedParties: {
            update: [
              {
                data: data,
                where: { id: party.id },
              },
            ],
          },
        }),
        feature: 'OTHER_PARTY_LICENSE_PLATE',
        existing_value: {
          manualVehicleLicensePlateState: party.manualVehicleLicensePlateState,
          manualVehicleLicensePlate: party.manualVehicleLicensePlate,
        },
      });
    }
  }

  return (
    <>
      <GodUtilities claim={claim} />
      {claimEditables.map(options => {
        const spec = specs[options.feature as keyof typeof specs] || null;
        return (
          <DescriptionList
            key={options.title}
            title={options.title}
            content={
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-2">
                  {options.source ? (
                    <MediaLoader source={options.source} />
                  ) : (
                    <div className="h-36 bg-gray-300 text-gray-600 p-4 text-center rounded flex items-center justify-center">
                      No media uploaded
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <Editor
                    spec={spec}
                    existing_value={options.existing_value}
                    updateClaim={(data: any) => {
                      updateClaim(options.makeUpdate(data));
                    }}
                  />
                </div>
              </div>
            }
          />
        );
      })}
    </>
  );
}
