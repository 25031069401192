import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Popover from 'react-tiny-popover';

import Button from '@common/components/Button';
import Modal from '@common/components/Modal';
import CopyBadge from '@frontend/components/elements/CopyBadge';
import {
  DeviceMobileIcon, InformationCircleIcon, LinkIcon, QrcodeIcon
} from '@heroicons/react/solid';

import { catTypes } from './consts';
import { CatastropheIncident } from './types';

const TABS = [
  { name: 'Link', key: 'link', icon: LinkIcon },
  { name: 'QR Code', key: 'qr', icon: QrcodeIcon },
  { name: 'Bulk SMS', key: 'sms', icon: DeviceMobileIcon },
];

export default function DispatchIncidentModal({
  show,
  onClose,
  incident,
}: {
  show: boolean;
  onClose: () => void;
  incident: CatastropheIncident;
}) {
  const [selectedTab, setSelectedTab] = useState('link');
  const [smsMessage, setSmsMessage] = useState('');
  const [smsRecipients, setSmsRecipients] = useState('');

  const [showMailMergeInfo, setShowMailMergeInfo] = useState(false);

  useEffect(() => {
    if (incident) {
      setSelectedTab('link');
      setSmsMessage(
        `It's Example Insurance here to help! Were you affected by ${incident.name}? Tap below to file a claim instantly. Reply STOP to opt-out.`,
      );
    }
  }, [incident]);

  return (
    <Modal
      isShowing={show}
      subtleCloseButton
      onClose={onClose}
      title={incident.name}
    >
      <div
        className={classNames('mt-4', selectedTab === 'sms' ? '-mb-2' : 'mb-8')}
      >
        <div className="text-sm text-gray-700">
          Utilize one or more dispatch options to deliver FNOLs to policyholders
          in need.
        </div>
        <div className="mt-2 border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {TABS.map(tab => (
              <button
                key={tab.name}
                className={classNames(
                  tab.key === selectedTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
                )}
                onClick={() => setSelectedTab(tab.key)}
              >
                <tab.icon
                  className={classNames(
                    tab.key === selectedTab
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
        <div className="mt-6">
          {selectedTab === 'link' ? (
            <div>
              <p className="text-sm text-gray-700">
                Provide the following link to file a new claim. You can also use
                query parameters to prefill the experience for each user.
                <Popover
                  isOpen={showMailMergeInfo}
                  position={['bottom']}
                  content={() => (
                    <div className="px-3 py-2 text-sm bg-blue-100 text-blue-500 font-medium rounded-md max-w-sm">
                      Specify optional query parameters <code>name</code>,{' '}
                      <code>phone</code>, and/or <code>email</code> to prefill
                      the experience and eliminate redundant user data entry.
                      For example:{' '}
                      <code className="block mt-2 break-all">
                        https://go.assured.claims/{incident.id}
                        ?name=John+Doe&phone=19876543210&email=john@example.com
                      </code>
                    </div>
                  )}
                  containerStyle={{ zIndex: '99999' }}
                  onClickOutside={() => setShowMailMergeInfo(false)}
                >
                  <span>
                    <InformationCircleIcon
                      className="w-4 h-4 inline-block ml-1 text-blue-500 hover:text-blue-700 cursor-pointer align-middle"
                      onClick={e => {
                        setShowMailMergeInfo(true);
                      }}
                    />
                  </span>
                </Popover>
              </p>
              <div className="mt-4 bg-gray-100 rounded-md border border-gray-300 p-4">
                <CopyBadge
                  shouldAlwaysShowIcon
                  copyBadgeClassNames="bg-gray-100 hover:bg-gray-300 transition-colors duration-100 ease-in-out focus:outline-none"
                  value={
                    <>
                      <p className="font-medium leading-tight">
                        {incident.name}
                      </p>
                      <p className="mt-1 leading-tight text-gray-500 break-all">
                        https://go.assured.claims/{incident.id}
                      </p>
                    </>
                  }
                  valueContainerClassNames="flex-1"
                />
              </div>
            </div>
          ) : selectedTab === 'qr' ? (
            <div>
              <p className="text-sm text-gray-700">
                Scan the following QR code to file a new claim.
              </p>
              <img
                className="mx-auto w-36 mt-4"
                src={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${encodeURIComponent(
                  `https://go.assured.claims/${incident.id}`,
                )}`}
              />
            </div>
          ) : selectedTab === 'sms' ? (
            <div>
              <p className="text-sm text-gray-700">
                Bulk dispatch an eFNOL experience to specific policyholders via
                SMS.
              </p>
              <div className="mt-4">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <textarea
                  name="message"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 sm:text-sm rounded-md"
                  autoFocus
                  value={smsMessage}
                  onChange={e => setSmsMessage(e.target.value)}
                  rows={3}
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="recipients"
                  className="block text-sm font-medium text-gray-700"
                >
                  Recipient phone numbers (one per line)
                </label>
                <textarea
                  name="recipients"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 sm:text-sm rounded-md"
                  value={smsRecipients}
                  placeholder={`(123) 456-7890\n(987) 654-3210`}
                  onChange={e => setSmsRecipients(e.target.value)}
                  rows={6}
                />
              </div>
              <Button
                containerClassName="mt-4"
                buttonColor="indigo"
                onClick={() => {}}
              >
                Bulk dispatch SMS
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
