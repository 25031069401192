import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { ClaimWorkflowStepComponent, StepComponentType } from '@common/types/ClaimWorkflow';

import { useStepComponentLookup, useTenantConfig } from '../../hooks';
import { generateValidationSchema } from '../../services/validation';
import TermsWidget from '../TermsWidget';
import ClaimWorkflowNotification from './ClaimWorkflowNotification';
import GenericComponent from './Generic';
import Title from './Title';
import {
  ClaimWorkflowStepFragmentData, StepComponentConfigFlags, StepComponentControlsBackHookProps,
  StepComponentShowsPrefillProps
} from './types/stepComponentTypes';

const VERBOSE_ERROR_THRESHOLD = 2;

type ClaimWorkflowStepExtendable = Partial<
  StepComponentShowsPrefillProps & StepComponentControlsBackHookProps
>;

interface ClaimWorkflowStepProps extends ClaimWorkflowStepExtendable {
  step: ClaimWorkflowStepFragmentData;
  onSubmit: (values: any) => void;
  loading?: boolean;
}

const ClaimWorkflowStep: React.FC<ClaimWorkflowStepProps> = ({
  step,
  onSubmit,
  ...rest
}) => {
  const tenantConfig = useTenantConfig();
  const [values, setValues] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [errorCount, setErrorCount] = useState<number>(0);

  const updateValue = (k: string | undefined, v: any) => {
    if (k) {
      setErrors(errors => ({ ...errors, [k]: undefined })); // clear any self-errors
      setValues(values => ({ ...values, [k]: v }));
    }
  };

  const stepSkippable = !!step.content.skip_label;

  const stepComponentHasConfigFlag = (
    c: ClaimWorkflowStepComponent,
    flag: keyof StepComponentConfigFlags,
  ) => {
    const Component = useStepComponentLookup(c.type as StepComponentType);
    if ('_result' in Component) {
      return Component?._result?.stepConfig?.[flag];
    } else {
      return Component?.stepConfig?.[flag];
    }
  };

  const manualSubmitRequired =
    step.content.manual_submit_label ||
    step.content.step_components.some(
      c =>
        stepComponentHasConfigFlag(c, 'manualSubmit') ||
        c.type === 'vehicle_damage_picker' ||
        c.type === 'interstitial',
    );

  const stepControlledTitle = step.content.step_components.some(c =>
    stepComponentHasConfigFlag(c, 'controlsTitle'),
  );

  const stepNotReadyToContinue = step.content.step_components.some(
    c =>
      (c.type === 'select_multi' &&
        c.field &&
        c.required &&
        !values[c.field]?.length) ||
      ((c.type === 'bodily_injury' ||
        (c.type === 'vehicle_seatmap' && c.single)) &&
        c.field &&
        !values[c.field]),
  );

  // Reset when the step changes.
  useEffect(() => {
    let newValues: Record<string, any> = {};
    (step.content.step_components || []).forEach(c => {
      if ('existing_value' in c) {
        if (c.field && typeof c.existing_value !== 'undefined') {
          newValues[c.field] = c.existing_value;
        }
      }
    });
    setValues(newValues);
    setErrors({});
    setErrorCount(0);
  }, [step]);

  const submit = (values: any, { force }: { force?: boolean } = {}) => {
    setErrors({});

    // No validation
    if (force === true) {
      onSubmit(values);
      return;
    }

    setTimeout(() => {
      const schema = generateValidationSchema({ step });
      schema
        .validate(values, { abortEarly: false })
        .then(() => onSubmit(values))
        .catch(e => {
          let errors: Record<string, string> = {};
          if (e.inner) {
            for (const innerE of e.inner) {
              errors[innerE.path] = innerE.errors;
            }

            let uncapturedErrors = [];
            for (const path in errors) {
              const c = step.content.step_components.find(
                c => c.field === path,
              );
              if (c && !stepComponentHasConfigFlag(c, 'controlsError')) {
                uncapturedErrors.push(errors[path]);
              }
            }
            if (uncapturedErrors.length) {
              errors['default'] = uncapturedErrors
                .filter((e, i, arr) => arr.indexOf(e) === i)
                .join('; ');
            }
          } else {
            errors['default'] =
              'Failed to save. Please try again, or contact us.';
          }
          setErrors(errors);
          setErrorCount(errorCount + 1);
        });
    }, 0);
  };

  const attemptSubmit = () => {
    const hasFields = step.content.step_components.some(c => !!c.field);
    const allEntered = step.content.step_components.every(c =>
      c.field ? typeof values[c.field] !== 'undefined' : true,
    );
    if (allEntered && hasFields) {
      submit(values);
    }
  };

  // Autosubmit if possible whenever values change
  useEffect(() => {
    if (!manualSubmitRequired) {
      attemptSubmit();
    }
  }, [values]);

  return (
    <div className="-mt-8">
      {step.notifications.length ? (
        <ClaimWorkflowNotification notification={step.notifications[0]} />
      ) : null}
      {step.content.step_components.map((step_component, i) => {
        const titleClass =
          i === 0 ? 'ClaimWorkflowTitle' : 'ClaimWorkflowInner';

        return (
          <div
            key={step_component.id}
            className={step_component.class_name_override || 'mt-8'}
          >
            {!stepControlledTitle ? (
              <Title
                title={step_component.title}
                subtitle={step_component.subtitle}
                titleClassName={titleClass}
                injectors={step_component.injectors}
              />
            ) : null}
            <GenericComponent
              primaryValue={
                step_component.field ? values[step_component.field] : null
              }
              updateValue={updateValue}
              step_component={step_component}
              error={
                step_component.field ? errors[step_component.field] : undefined
              }
              showErrorMessages={errorCount >= VERBOSE_ERROR_THRESHOLD}
              forceSubmit={() => submit(values)}
              attemptSubmit={() => attemptSubmit()}
              titleClassName={titleClass}
              otherValue={
                'other_field' in step_component && step_component.other_field
                  ? values[step_component.other_field]
                  : undefined
              }
              source={
                'source_field' in step_component && step_component.source_field
                  ? values[step_component.source_field]
                  : undefined
              }
              allValues={values}
              {...rest}
            />
          </div>
        );
      })}
      {errors.default ? (
        <div className="error-message mt-6">{errors.default}</div>
      ) : null}
      {manualSubmitRequired || stepSkippable ? (
        <div
          key={step.id}
          className={classNames(
            'ClaimWorkflowInner mt-4 flex flex-wrap justify-center',
            step.content.step_components.length > 1 && 'mt-6',
          )}
        >
          {manualSubmitRequired ? (
            <button
              className={classNames(
                'btn btn-blue sm:order-last',
                stepNotReadyToContinue && 'btn-disabled',
              )}
              onClick={() => !stepNotReadyToContinue && submit(values)}
            >
              {step.content.manual_submit_label || 'Continue'}
            </button>
          ) : null}
          {stepSkippable ? (
            <button
              className="btn btn-subtle sm:order-first"
              onClick={() => submit(values, { force: true })}
            >
              {step.content.skip_label || 'Skip'}
            </button>
          ) : null}
        </div>
      ) : null}
      {step.content.include_terms_footer ? (
        <div>
          <TermsWidget tenantName={tenantConfig?.displayName} />
        </div>
      ) : null}
    </div>
  );
};

export default ClaimWorkflowStep;
