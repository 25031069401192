import React, { useRef, useState } from 'react';

import Modal from '@common/components/Modal';

import { catTypes } from './consts';

export default function NewIncidentModal({
  show,
  onClose,
  onSubmit,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: ({ name, type }: { name: string; type: string }) => void;
}) {
  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <Modal
      isShowing={show}
      onClose={onClose}
      onSubmit={() => {
        if (!formRef.current) {
          return;
        }

        const formData = new FormData(formRef.current),
          name = formData.get('name') as string,
          type = formData.get('type') as string;

        if (!name || !type) {
          window.alert(`Please provide the required information.`);
          return;
        }

        onSubmit({ name, type });
      }}
      title={`Create new catastrophe incident`}
    >
      <div className="mt-4 mb-8">
        <div className="text-sm text-gray-600">
          Create a new incident. Incidents are used to deliver targeted eFNOL
          experiences, and can then be used to segment and triage inbound
          eFNOLs.
        </div>
        <form
          ref={r => {
            formRef.current = r;
          }}
          onSubmit={e => e.preventDefault()}
          className="mt-4 mb-6 grid grid-cols-1 gap-4"
        >
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Internal name
            </label>
            <input
              id="name"
              name="name"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 sm:text-sm rounded-md"
              type="text"
              autoFocus
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Customer-facing name
            </label>
            <input
              id="customer_label"
              name="customer_label"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 sm:text-sm rounded-md"
              type="text"
            />
          </div>
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700"
            >
              Catastrophe type
            </label>
            <select
              id="type"
              name="type"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {catTypes.map(t => (
                <option>{t.label}</option>
              ))}
            </select>
          </div>
        </form>
      </div>
    </Modal>
  );
}
