import React, { useState } from 'react';

import Button from '@common/components/Button';
import { MailIcon, PhoneIcon, PlusCircleIcon } from '@heroicons/react/solid';

import Layout from '../../shared/Layout';
import { catTypes } from './consts';
import DispatchIncidentModal from './DispatchIncidentModal';
import IncidentTable from './IncidentTable';
import NewIncidentModal from './NewIncidentModal';
import { CatastropheIncident } from './types';

const DEFAULT_CATASTROPHE_INCIDENTS: CatastropheIncident[] = [
  {
    id: 'qfmdpv',
    name: 'Hurricane Henri',
    type: 'Hurricane',
    startedAt: new Date('2021-08-12T14:32'),
    claimCount: 196433,
  },
  {
    id: 'h7dnhm',
    name: 'Winter Storm Uri',
    type: 'Winter storm',
    startedAt: new Date('2021-02-13T14:32'),
    endedAt: new Date('2021-02-17T14:32'),
    claimCount: 69422,
  },
  {
    id: 'et6079',
    name: 'Wildfire near Sonoma, CA',
    type: 'Wild fire',
    startedAt: new Date('2021-08-13T14:32'),
    claimCount: 201,
  },
  {
    id: 'b3mtbp',
    name: 'Flood near Memphis, TN',
    type: 'Flood',
    startedAt: new Date('2021-08-11T14:32'),
    claimCount: 8,
  },
  {
    id: 'mod9y6',
    name: 'Tornado near Oklahoma City, OK',
    type: 'Tornado',
    startedAt: new Date('2021-08-01T14:32'),
    claimCount: 938,
  },
  {
    id: 'zzet73',
    name: 'Hailstorm near Palo Alto, CA',
    type: 'Hailstorm',
    startedAt: new Date('2021-08-11T14:32'),
    claimCount: 7,
  },
];

const Heading: React.FC<{ rightAction?: JSX.Element }> = ({
  children,
  rightAction,
}) => {
  return (
    <div className="mt-4 h-6 relative">
      <div className="absolute top-3 w-full h-px bg-gray-300"></div>
      <h3 className="absolute left-0 top-0 h-6 text-lg leading-6 font-medium text-gray-900 pr-2 bg-gray-50">
        {children}
      </h3>
      {rightAction ? (
        <div className="absolute right-0 top-0 h-6 pl-2 -mt-2 bg-gray-50">
          {rightAction}
        </div>
      ) : null}
    </div>
  );
};

export default function ClaimDashboard() {
  const [incidents, setIncidents] = useState<CatastropheIncident[]>(
    DEFAULT_CATASTROPHE_INCIDENTS,
  );
  const [showNewIncidentModal, setShowNewIncidentModal] = useState(false);
  const [showDispatchIncidentModal, setShowDispatchIncidentModal] =
    useState(false);
  const [selectedIncident, setSelectedIncident] =
    useState<CatastropheIncident | null>(null);

  const updateIncident = (newIncident: CatastropheIncident) => {
    setTimeout(
      () =>
        setIncidents(i =>
          i.map(i => {
            if (i.id === newIncident.id) {
              return newIncident;
            } else {
              return i;
            }
          }),
        ),
      275,
    );
  };

  const dispatchIncident = (incident: CatastropheIncident) => {
    setSelectedIncident(incident);
    setShowDispatchIncidentModal(true);
  };

  return (
    <>
      <Layout
        navigation={[
          { href: '/cat', name: 'CAT' },
          { current: true, name: 'Management' },
        ]}
      >
        <main className="mt-8 max-w-7xl mx-auto px-8 flex flex-col pb-12">
          <NewIncidentModal
            show={showNewIncidentModal}
            onClose={() => setShowNewIncidentModal(false)}
            onSubmit={instance => {
              setShowNewIncidentModal(false);
              setTimeout(() => {
                setIncidents(i => [
                  ...i,
                  {
                    id: fakeId(6),
                    name: instance.name,
                    type: instance.type,
                    startedAt: new Date(),
                    claimCount: 0,
                  },
                ]);
              }, 525);
            }}
          />
          <DispatchIncidentModal
            show={showDispatchIncidentModal}
            incident={selectedIncident || incidents[0]}
            onClose={() => setShowDispatchIncidentModal(false)}
          />
          <Heading
            rightAction={
              <Button
                buttonColor="indigo"
                onClick={() => setShowNewIncidentModal(true)}
              >
                <PlusCircleIcon className="w-5 h-5 mr-2" />
                New incident
              </Button>
            }
          >
            Active incidents
          </Heading>
          <IncidentTable
            incidents={incidents.filter(i => !i.endedAt)}
            onUpdateIncident={updateIncident}
            onDispatchIncident={dispatchIncident}
          />
          <Heading>Past incidents</Heading>
          <IncidentTable
            incidents={incidents.filter(i => !!i.endedAt)}
            onUpdateIncident={updateIncident}
            onDispatchIncident={dispatchIncident}
          />
        </main>
      </Layout>
    </>
  );
}

function fakeId(length: number) {
  let result = '';
  let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
