import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';

import CopyBadge from '@frontend/components/elements/CopyBadge';
import {
  CalendarIcon, ChatIcon, DocumentTextIcon, LightningBoltIcon, XIcon
} from '@heroicons/react/outline';
import { PhoneIcon } from '@heroicons/react/solid';

import { catTypes } from './consts';
import { CatastropheIncident } from './types';

export default function IncidentTable({
  incidents,
  onUpdateIncident,
  onDispatchIncident,
}: {
  incidents: CatastropheIncident[];
  onUpdateIncident: (i: CatastropheIncident) => void;
  onDispatchIncident: (i: CatastropheIncident) => void;
}) {
  return (
    <ul
      role="list"
      className="my-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
    >
      {incidents.map(incident => {
        const catType = catTypes.find(t => t.key === incident.type);
        return (
          <li
            key={incident.id}
            className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
          >
            <div className="w-full flex items-center justify-between p-6 pb-4 space-x-1">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="text-gray-900 text-lg font-medium truncate">
                    {incident.name}
                  </h3>
                </div>
                <p className="mt-2 text-gray-500 text-sm flex items-center">
                  <CalendarIcon className="w-4 h-4 mr-2" />
                  <span>
                    {moment(incident.startedAt).format('ll')} &ndash;{' '}
                    {incident.endedAt
                      ? moment(incident.endedAt).format('ll')
                      : 'ongoing'}
                  </span>
                </p>
                <p className="mt-1 text-gray-500 text-sm flex items-center">
                  <DocumentTextIcon className="w-4 h-4 mr-2" />
                  <span>{incident.claimCount.toLocaleString()} claims</span>
                </p>
                <p className="mt-2 text-gray-500 text-sm truncate">
                  {!incident.endedAt ? (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                      Accepting claims
                    </span>
                  ) : (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-800 text-xs font-medium bg-gray-100 rounded-full">
                      No longer accepting claims
                    </span>
                  )}
                </p>
                <p className="text-gray-400 text-sm truncate">
                  <CopyBadge
                    shouldAlwaysShowIcon
                    copyBadgeClassNames="bg-gray-100 hover:bg-gray-300 transition-colors duration-100 ease-in-out focus:outline-none"
                    value={
                      <div className="py-2 pr-1">
                        go.assured.claims/
                        {incident.id}
                      </div>
                    }
                  />
                </p>
              </div>
              <div className="flex-shrink-0 text-5xl leading-none">
                {catType?.icon}
              </div>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                  <button
                    onClick={() => onDispatchIncident(incident)}
                    className={classNames(
                      'relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-indigo-700 font-medium border border-transparent rounded-bl-lg hover:text-indigo-500 focus:outline-none',
                      incident.endedAt &&
                        'opacity-25 pointer-events-none cursor-not-allowed',
                    )}
                  >
                    <ChatIcon
                      className="w-5 h-5 text-indigo-700"
                      aria-hidden="true"
                    />
                    <span className="ml-3">Dispatch</span>
                  </button>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                  <button
                    onClick={() =>
                      onUpdateIncident({
                        ...incident,
                        endedAt: incident.endedAt ? null : new Date(),
                      })
                    }
                    className={classNames(
                      'relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm font-medium border border-transparent rounded-br-lg hover:opacity-75 focus:outline-none text-gray-500',
                      incident.endedAt
                        ? 'hover:text-green-600'
                        : 'hover:text-red-600',
                    )}
                  >
                    {incident.endedAt ? (
                      <LightningBoltIcon className="w-5 h-5" />
                    ) : (
                      <XIcon className="w-5 h-5" />
                    )}
                    <span className="ml-3">
                      {incident.endedAt ? 'Reactivate' : 'Deactivate'}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
