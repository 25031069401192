import React from 'react';

interface CopyIconProps {
  /** Optional fill to be passed to the path */
  fill?: string;
}

const CopyIcon: React.FC<CopyIconProps> = ({ fill, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M13.3 3.4H8v2.3H4.9v10h7.7v-2.3h3.1V5.9l-2.4-2.5zm-1.4 10.1V15H5.7V6.5h3.5v2.7h2.7v4.3zm-2-6.9l1.9 1.9H9.9V6.6zm.3-.8H8.8V4.2h3.5v2.7H15v5.8h-2.3V8.2l-2.5-2.4zM13 4.3l1.9 1.9H13V4.3z"
        fill={fill}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CopyIcon;
